const comBg = (() => {

  const bgWrap = document.querySelectorAll('.js-com-bg');
    gsap.set(bgWrap, {
        opacity: 0,
        y: 100,
      });
    gsap.to(bgWrap, {
      opacity: 1,
      y: 0,
      duration: 1.0,
      ease: "power4.inout",
      scrollTrigger: {
        trigger: bgWrap,
        start: 'top 85%',
        // markers: true,
      }
    });

})();
export default comBg;